<template>
  <div :data-testid="dataTestid" :class="[
      'level-item [&:not(:last-child)]:mb-3 flex items-center justify-center basis-auto shrink-0 grow-0',
      props.isTextCentered && 'text-center',
      props.isNarrow ? '!grow-0' : 'md:!mb-0 md:[&:not(:last-child)]:mr-3',
      ]">
    <slot/>
  </div>
</template>

<script setup lang="ts">
type Props = {
  isTextCentered?: boolean,
  isNarrow?: boolean,
  dataTestid?:string,
}

const props = withDefaults(defineProps<Props>() , {
  dataTestid:'organism-levelitem'
})
</script>